<template>
  <b-modal id="select-font-modal" hide-footer hide-header>
    <div class="d-block text-left">
      <b-form-group
        id="input-group-back-margin-right"
        label="Braille Schriftart"
        label-for="field-margin-right"
        label-size="sm"
      >
        <b-form-select
          v-model="name" name="fontName" :value="name" class="form-control"
          size="sm"
          aria-placeholder="Schrift auswählen"
        >
          <option value="" disabled selected>
            Schrift auswählen
          </option>
          <option v-for="(item, key) in filterLanguage()" :key="key" :value="key">
            {{ item }}
          </option>
        </b-form-select>
      </b-form-group>
      <b-container>
        <b-row>
          <div class="offset-2 col-sm-4">
            <b-button
              type="submit"
              variant="primary"
              :disabled="!name.length"
              @click="loadFont(); useModal('select-font-modal').hide();"
            >
              Öffnen
            </b-button>&nbsp;
          </div>
          <div class="col-sm-4">
            <b-button
              type="submit"
              variant="secondary"
              @click="useModal('select-font-modal').hide();"
            >
              Schließen
            </b-button>
          </div>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>
<script>

    import {mapActions, mapState} from "vuex";
    import {useModal} from "bootstrap-vue-next";

    export default {
     name: 'ModalSelectFont',
     data() {
      return {
       fontName: '',
       show: true
      }
     },
     computed: {
      ...mapState({
       fontNames: state => state.fontEditor.fontNames,
       name: state => state.fontEditor.name,
      }),
      name: {
       get: function() {
        return this.$store.state.fontEditor.name
       },
       set: function(name) {
        this.$store.commit('fontEditor/updateName', name)
       }
      }
     },
     mounted() {
      this.loadFontList();
     },
     methods: {
      useModal,
      filterLanguage() {
       return this.fontNames;
      },
      ...mapActions({
       loadFontList: 'fontEditor/loadFontList',
       loadFont: 'fontEditor/loadFont',
       updateName: 'fontEditor/updateName'
      }),
     },
    }

</script>

<style></style>