<template>
  <span class="attribute">
    <b-badge
      v-b-tooltip.hover :title="getAttributeDescription()" :variant="getButtonVariant()"
      @click="toggleAttribute"
    >{{ getDisplayValue() }}</b-badge>
  </span>
</template>

<script>
    import {mapState, mapGetters} from "vuex";
    import {BFT_ATTR_PUNCTATION, BFT_ATTR_NUMDIGIT, BFT_ATTR_MATHSIGN, BFT_ATTR_CAPITAL, BFT_ATTR_SMALLETTER} from "../../../store/store";

    export default {
     name: "Attribute",
     components: {
     },
     props: {
      letterKey: {
       type: Number,
       default: 0
      },
      attribute: {
       type: Number,
       default: BFT_ATTR_CAPITAL
      }
     },
     data() {
      return {
       cols: 3,
      }
     },
     computed: {
      ...mapState(['letters']),
      ...mapGetters(['getAttributeCValue'])
     },
     methods: {
      getButtonVariant() {
       return (this.isSet() ? 'dark' : 'light');
      },
      isSet() {
       return this.$store.getters["fontEditor/getAttributeValue"](this.letterKey, this.attribute);
       // return this.$store.getters.getAttributeValue(this.letterKey, this.attribute);
      },
      toggleAttribute() {
       this.$store.commit({
        type: 'fontEditor/toggleAttribute',
        attribute: this.attribute,
        key: this.letterKey
       });
       this.$forceUpdate()
      },
      getDisplayValue() {
       if (this.attribute == BFT_ATTR_CAPITAL)
        return 'ABC';
       if (this.attribute == BFT_ATTR_SMALLETTER)
        return 'abc';
       if (this.attribute == BFT_ATTR_NUMDIGIT)
        return '123';
       if (this.attribute == BFT_ATTR_MATHSIGN)
        return '+-*';
       if (this.attribute == BFT_ATTR_PUNCTATION)
        return ',.;';
      },
      getAttributeDescription() {
       if (this.attribute == BFT_ATTR_CAPITAL)
        return 'Großbuchstaben';
       if (this.attribute == BFT_ATTR_SMALLETTER)
        return 'Kleinbuchstaben';
       if (this.attribute == BFT_ATTR_NUMDIGIT)
        return 'Ziffern';
       if (this.attribute == BFT_ATTR_MATHSIGN)
        return 'mathematische Zeichen';
       if (this.attribute == BFT_ATTR_PUNCTATION)
        return 'Interpunktionszeichen';
      },
     }
    }
</script>

<style scoped>
  .attribute {
    margin-left: 3px;
    font-size: 14px;
  }

  .attribute .badge {
    cursor: pointer;
  }
</style>