<template>
  <div role="tablist" class="settingsCol">
    <b-card no-body class="mb-1">
      <b-tabs pills card class="tabs-list">
        <b-tab title="Datei" active>
          <b-card-text><FileSettings /></b-card-text>
        </b-tab>

        <b-tab title="Braille">
          <b-card-text><BrailleCodingSettings /></b-card-text>
        </b-tab>

        <b-tab title="Layout">
          <b-card-text><GlobalSettings /></b-card-text>
        </b-tab>
        <template #tabs-end>
          <b-nav-item role="presentation" href="#" style="position: absolute; right: 0px;" @click.prevent="toggleCollapseMenu()">
            <fe-arrow-left v-b-tooltip.hover title="Einstellungsmenü ausblenden" @click="toggleCollapseMenu()" />
          </b-nav-item>
        </template>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
    import GlobalSettings from "@/components/PageLayoutSettings";
    import BrailleCodingSettings from "@/components/BrailleCodingSettings";
    import FileSettings from "@/components/FileSettings";
    import FeArrowLeft from '@/components/icons/FeArrowLeft'

    export default {
     name: "SettingsColumn",
     components: {GlobalSettings, BrailleCodingSettings, FileSettings , FeArrowLeft},
     methods: {
      toggleCollapseMenu() {
       this.$store.commit('collapseMenu', !this.collapseMenu);
      },
     },
    }
</script>

<style scoped>

    .settingsCol {
        height: 100%;
    }
    .card-body {
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .card {
      border-radius: unset;
    }

    .tab-container {
        /*overflow-y: auto;
        height: calc(100vh - 150px);*/
    }

    .tab-container.spaceholder {
      margin-bottom: 10px;
      margin-top: 10px;
    }
</style>