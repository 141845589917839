<template>
  <div class="top-navbar">
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand v-b-tooltip.hover :to="{name: 'main'}" title="Zurück zur Hauptseite">
        <img alt="dotCloud" src="./../assets/logo.png" height="25">
        dotCloud
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" />

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="isDocumentActive">
          <b-nav-item v-b-tooltip.hover :to="{name: 'main'}" title="Zurück zum Dokument">
            {{ $store.state.documentName }}
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav v-if="!isLoggedIn" right class="ml-auto">
          <b-nav-item :to="{name: 'impressum'}">
            Impressum
          </b-nav-item>
          <b-nav-item :to="{name: 'privacy'}">
            Datenschutz
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav v-if="isLoggedIn" right class="ml-auto">
          <b-nav-item :to="{name: 'bft-editor'}">
            BFT-Editor
          </b-nav-item>
          <b-nav-item :to="{name: 'dotcloud-app'}">
            Dotcloud App
          </b-nav-item>
          <b-nav-item :to="{name: 'impressum'}">
            Impressum
          </b-nav-item>
          <b-nav-item :to="{name: 'privacy'}">
            Datenschutz
          </b-nav-item>
          <b-nav-item @click="logout">
            <span>Logout</span>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
     name: "Navbar",
     components: {
     },
     computed : {
      isLoggedIn : function(){ return this.$store.getters.isAuthenticated },
      isDocumentActive : function(){ return !!this.documentId },
      ...mapState(['documentId', 'documentName'])
     },
     methods: {
      async logout (){
       await this.$store.dispatch('LogOut')
       await this.$router.push('/login').catch(() => {});
      },
      async impressum(){
       await this.$router.push('/impressum').catch(() => {});
      },
      async datenschutz(){
       await this.$router.push('/datenschutz').catch(() => {});
      }
     },
    }
</script>

<style scoped>
.bg-info {
  background-color: #0087b8 !important;
}
</style>