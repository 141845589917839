<template>
  <div class="braille-coding-settings-container tab-container">
    <b-form
      v-if="show"
      class="braille-coding-form"
      @reset="onReset"
      @submit.prevent="submitBrailleCoding($data)"
    >
      <b-form-group
        id="input-group-languages"
        label="Sprache:"
        label-for="field-languages"
        label-size="sm"
        class="form-group"
      >
        <b-form-select
          v-model="form.language" name="language" :value="form.language" class="form-control"
          size="sm" @load="loadLanguages"
        >
          <option>Sprache auswählen</option>
          <option v-for="language in languages" :key="language.id" :value="language.id">
            {{ language.name }}
          </option>
        </b-form-select>
      </b-form-group>

      <b-form-group
        id="input-group-braille"
        label="Schriftstufe:"
        label-for="field-languages"
        label-size="sm"
        class="form-group"
      >
        <b-form-select
          v-model="form.braille" name="braille" :value="form.braille" class="form-control"
          size="sm"
        >
          <option v-for="braille in filterLanguage()" :key="braille.id" :value="braille.id">
            {{ braille.name }}
          </option>
        </b-form-select>
      </b-form-group>

      <b-button
        type="submit"
        variant="primary"
      >
        Übernehmen
      </b-button>&nbsp;
      <b-button
        type="reset"
        variant="danger"
      >
        Zurücksetzen
      </b-button>
    </b-form>
    <div
      class="mt-3"
      style="display: none;"
    >
      <pre class="m-0">{{ form }}</pre>
    </div>
  </div>
</template>

<script>
    import {mapActions, mapState} from 'vuex'

    export default {
     name: 'BrailleCodingSettingsForm',
     data() {
      return {
       form: {
        paper: '',
        language: 'Deutsch',
        braille: 1
       },
       show: true
      }
     },
     computed: {
      console: () => console,
      window: () => window,
      paperHeightValidation() {
       return this.form.paperHeight <= 12
      },
      paperWidthValidation() {
       return this.form.paperWidth <= 14
      },
      ...mapState(['languages'])
     },
     created() {
      this.$store.state.formBrailleCoding = this.form;
     },
     mounted() {
      this.loadLanguages();
     },
     methods: {
      onSubmit(evt) {
       evt.preventDefault()
      },
      filterValue: (obj, key, value) => Object.values(obj).filter(v => v[key] === value),
      filterLanguage() {
       let brailles = this.filterValue(this.languages, 'id', this.form.language);
       return brailles.length === 0 ? [] : brailles[0]['keys'];
      },
      onReset(evt) {
       evt.preventDefault()
       // Reset our form values
       this.form.language = 'Deutsch'
       this.form.braille = 1
       // Trick to reset/clear native browser form validation state
       this.show = false
       this.$nextTick(() => {
        this.show = true
       })
      },
      ...mapActions({
       loadLanguages: 'loadLanguages',
       submitBrailleCoding: 'submitBrailleCoding'
      }),
     }
    }
</script>

<style scoped>
  .braille-coding-form {
    padding: 15px;
  }

  .tab-container {
    height: calc(100vh - 127px);
    overflow-y: scroll;
  }
</style>