<template>
  <b-modal id="cu-editor-modal" hide-footer hide-header>
    <div class="d-block text-center">
      <b-container>
        <b-row>
          <h5>{{ replFilename }}</h5>
        </b-row>
        <b-row>
          <b-table-lite
            size="sm" striped hover foot-clone
            :items="replacements"
            :fields="fields"
          >
            <template #cell(pattern)="row" size="sm">
              <b-form-input v-model="row.item.pattern" size="sm" />
            </template>
            <template #cell(replacement)="row" size="sm">
              <b-form-input v-model="row.item.replacement" size="sm" />
            </template>
            <template #cell(x)="row" size="sm">
              <b-button size="sm" variant="outline-danger" @click="remove(row.index)">
                <trash2-icon size="1x" />
              </b-button>
            </template>
            <template #foot(x)="" size="sm">
              <b-button size="sm" variant="info" @click="add()">
                <plus-icon size="1x" />
              </b-button>
            </template>
            <template #foot()="" size="sm">
&nbsp;
            </template>
          </b-table-lite>
        </b-row>
        <b-row>
          <div class="offset-2 col-sm-4">
            <b-button
              type="submit"
              variant="primary"
              @click="saveReplacements()"
            >
              Übernehmen
            </b-button>
          </div>
          <div class="col-sm-4">
            <b-button
              type="submit"
              variant="secondary"
              @click="useModal('cu-editor-modal').hide()"
            >
              Schließen
            </b-button>
          </div>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>
<script>

    import {mapActions, mapState} from "vuex"
    import Trash2Icon from '@/components/icons/FeTrash.vue'
    import PlusIcon from '@/components/icons/FePlus.vue'
    import {useModal} from "bootstrap-vue-next";

    export default {
     name: 'ModalNewFont',
     components: {
      Trash2Icon,
      PlusIcon
     },
     data() {
      return {
       fontName: '',
       baseFontName: null,
       show: true,
       fields: [
        {label: 'Wort', key: "pattern"},
        {label: 'Ersatz', key: "replacement"},
        {label: '', key: "x"}
       ]
      }
     },
     computed: {
      ...mapState({
       replacements: state => state.cuEditor.replacements,
       replFilename: state => state.cuEditor.replFilename
      }),
      name: {
       get () {
        return this.$store.state.name
       },    set (name) {
        this.$store.commit('updateName', name)
       }
      }
     },
     mounted() {
     },
     methods: {
      useModal,
      remove(index) {
       this.replacements.splice(index, 1);
      },
      add() {
       this.replacements.push({pattern: '', replacement: ''})
      },
      ...mapActions({
       getReplacements: 'getReplacements',
      }),
      ...mapActions('cuEditor', ['getReplacements', 'saveReplacements'])
     },
    }

</script>

<style></style>