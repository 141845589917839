'use strict';

import Clip2 from './Clip/index.js';

const VueClipZwei = {
    install(app) {
        app.component('VueClipZwei', Clip2);
    },
    name: 'VueClipZwei'
};

/**
 * When required globally
 *
 */
if (typeof window !== 'undefined' && typeof window.Vue !== 'undefined') {
    VueClipZwei.install(window.Vue);
}


export default VueClipZwei;