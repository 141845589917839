<template>
  <b-card-text>
    <b-container class="settings-container">
      <b-button class="btn-block" variant="primary" @click="newDocument()">
        Neu
      </b-button>
      <b-button class="btn-block" variant="primary" :disabled="!isDocumentActive" @click="downloadWithAxios()">
        Download
      </b-button>
      <b-button class="btn-block" variant="primary" :disabled="!isDocumentActive" @click="redirectToPraegen()">
        Prägen
      </b-button>
    </b-container>
  </b-card-text>
</template>

<script>
    import {mapActions, mapState} from 'vuex'
    import axios from 'axios'
    import {config} from "@/config";

    export default {
     name: "FileSettings",
     computed: {
      isDocumentActive : function(){ return !!this.documentId },
      ...mapState(['documentId', 'documentName'])
     },
     methods: {
      forceFileDownload(response) {
       const url = window.URL.createObjectURL(new Blob([response.data]))
       const link = document.createElement('a')
       link.href = url
       link.setAttribute('download', response.headers['content-disposition'].split('filename=')[1].split(';')[0])
       document.body.appendChild(link)
       link.click()
      },
      downloadWithAxios(){
       axios({
        url: this.getDocumentUrl(),
        responseType: 'arraybuffer',
       })
        .then(response => {
         this.forceFileDownload(response)
        })
        .catch(() => console.log('error occured'))
      },
      getDocumentUrl() {
       return config.api + config.documentPath + '/' + this.$store.state.documentId
      },
      async redirectToPraegen(){
       await this.$router.push({name: 'praegen'}).catch(() => {});
      },
      ...mapActions({
       newDocument: 'newDocument'
      }),
     }
    }
</script>

<style scoped>
  .settings-container {
    padding: 15px;
    height: calc(100vh - 127px);
    overflow-y: scroll;
  }
</style>