import { createApp } from 'vue';
import App from './App.vue';
import '@babel/polyfill';
import 'mutationobserver-shim';
import axios from 'axios';
import VueAxios from 'vue-axios';
import {BootstrapVueNext} from 'bootstrap-vue-next';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
import 'bootstrap/dist/css/bootstrap.css';
import store from './store/store';
import router from './router/index';
import VueClipZwei from "@/components/Uploader";

const app = createApp(App);

// Use BootstrapVue
app.use(BootstrapVueNext);



// Use VueAxios with axios
app.use(VueAxios, axios);
const options = {
    // You can set your default options here
};

app.use(Toast, options);

// Mount the app with router and store
app.use(store);
app.use(router);
app.use(VueClipZwei);
app.mount('#app');

