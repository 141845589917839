import {createApp} from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {config} from "@/config";
import App from "@/App.vue";
import { useToast } from 'vue-toastification'


const app = createApp(App);
app.use(Vuex)
app.use(VueAxios, axios)

const toast = useToast()

export const BFT_ATTR_PUNCTATION = 0x01;       /* Markiert Satzzeichen */
export const BFT_ATTR_NUMDIGIT = 0x02;        /* Markiert Ziffer */
export const BFT_ATTR_MATHSIGN = 0x04;         /* Markiert math. Zeichen */
export const BFT_ATTR_CAPITAL = 0x08;          /* Markiert Großbuchstaben */
export const BFT_ATTR_SMALLETTER = 0x10;       /* Markiert Kleinbuchstaben */

export const BFT_NO_INDICES = 283; // Anzahl der Zeichen (ohne Attributebytes(+256))

export const BFT_PAGE_ASCII = 0;
export const BFT_PAGE_ARAB_NUMBERS = 1;
export const BFT_PAGE_ROM_NUMBERS = 2;
export const BFT_PAGE_SPECIAL = 3;

const bitmasks = [
    0x01, 0x02, 0x04, 0x08, 0x10, 0x20, 0x40, 0x80
];

const pages = [
    {
        start: 0,
        end: 255,
        title: 'Braille-Code für ASCII-Bereich'
    },
    {
        start: 256,
        end: 265,
        title: 'Braille-Code für arabische Ziffern'
    },
    {
        start: 266,
        end: 272,
        title: 'Braille-Code für römische Zahlen'
    },
    {
        start: 273,
        end: 282,
        title: 'Automatische Formatierungszeichen'
    }
]

const listSpecialLetter = [
    {
        id: 273,
        title: 'Automatisches Nummernzeichen'
    },
    {
        id: 274,
        title: 'Automatisches Bold-Zeichen im Text'
    },
    {
        id: 275,
        title: 'Automatisches Capital-Zeichen im Text'
    },
    {
        id: 276,
        title: 'Automatisches Emphasize-Zeichen im Text'
    },
    {
        id: 277,
        title: 'Automatisches Fremdsprach-Zeichen im Text'
    },
    {
        id: 278,
        title: 'Automatisches Bold-Zeichen am Rand'
    },
    {
        id: 279,
        title: 'Automatisches Emphasize-Zeichen am Rand'
    },
    {
        id: 280,
        title: 'Trennungszeichen'
    },
    {
        id: 281,
        title: 'Unterstreichungszeichen'
    },
    {
        id: 282,
        title: 'Versbegrenzerzeichen'
    },
]

const fontEditor = {
    namespaced: true,
    state: {
        error: {
            has: false,
            message: ''
        },
        name: '',
        letters: [],
        fontNames: [],
        isModified: false
    },
    getters: {
        hasError (state) {
            return state.error.has
        },
        lettersCount (state) {
            return state.letters.length
        },
        getAttributeValue: (state) => (key, attribute) => {
            if (![BFT_ATTR_PUNCTATION, BFT_ATTR_NUMDIGIT, BFT_ATTR_MATHSIGN,
                BFT_ATTR_CAPITAL, BFT_ATTR_SMALLETTER].includes(attribute))
                return false;
            return state.letters[key+256] & attribute;
        },
        getDot: (state) => (key, number) => {
            if (number < 0 || number > 8) {
                return false;
            }
            return state.letters[key] & bitmasks[number-1];
        },
        getPage: () => (number) => {
            return pages[number];
        },
        isSpecialLetter: () => (number) => {
            return number >= 273
        },
        getSpecialLetter: () => (number) => {
            return listSpecialLetter.find(i => i.id === number)
        },
    },
    actions: {
        loadFontList (context) {
            axios
                .get(config.api + config.fontPath)
                .then( response => context.commit('fontNames', response.data) )
                .catch( response => { console.log(response) } );
        },
        loadFont (context) {
            axios
                .get(config.api + config.fontPath + '/' + this.state.fontEditor.name)
                .then( (response) => {
                    context.commit('font', response.data)
                    context.commit('font', response.data)
                    toast('Schrift ' + this.state.fontEditor.name + ' erfolgreich geladen.');
                })
                .catch(() => {
                    //toast.danger('Schrift ' + this.state.fontEditor.name + ' konnte nicht geladen werden.', 'Fehler', 'danger');
                } );
        },
        createFont(context, payload) {
            context.commit("clearError");
            return axios
                .put(config.api + config.fontPath + '/' + payload.fontName, {baseFontName: payload.baseFontName})
                // eslint-disable-next-line no-unused-vars
                .then( response => {
                    context.commit('font', response.data)
                    context.dispatch('loadFontList')
                } )
                .catch( error => {
                    let message = 'Speichern fehlgeschlagen, ein unbekannter Fehler ist aufgetreten.';
                    if (error.response.status == 409) {
                        message = 'Font ist schon vorhanden.';
                    }
                    context.commit("setError", message);
                } );
        },
        saveFont (context) {
            axios
                .post(config.api + config.fontPath + '/' + this.state.fontEditor.name, this.state.fontEditor.letters)
                // eslint-disable-next-line no-unused-vars
                .then( response => {
                    context.commit('font', response.data)
                    toast.success('Schrift ' + context.state.name + ' wurde gespeichert');
                })
                // eslint-disable-next-line no-unused-vars
                .catch( response => {
                    // toast.danger('Speichern fehlgeschlagen, ein unbekannter Fehler ist aufgetreten.')
                } );
        }
    },
    mutations: {
        toggleAttribute (state, data) {
            if (![BFT_ATTR_PUNCTATION, BFT_ATTR_NUMDIGIT, BFT_ATTR_MATHSIGN,
                BFT_ATTR_CAPITAL, BFT_ATTR_SMALLETTER].includes(data.attribute))
                return false;
            if (state.name.length > 0) {
                this.state.fontEditor.isModified = true;
            }
            if (this.state.fontEditor.letters[data.key+256] & data.attribute) {
                this.state.fontEditor.letters[data.key+256] &= ~data.attribute
            } else {
                this.state.fontEditor.letters[data.key+256] |= data.attribute
            }
        },
        toggleDot (state, data) {
            if (data.dot < 0 || data.dot > 8) {
                return false;
            }
            if (state.name.length > 0) {
                this.state.fontEditor.isModified = true;
            }
            let bitmask = bitmasks[data.dot-1];
            if (this.state.fontEditor.letters[data.key] & bitmask) {
                this.state.fontEditor.letters[data.key] &= ~bitmask
            } else {
                this.state.fontEditor.letters[data.key] |= bitmask
            }
        },
        updateName (state, data) {
            this.state.fontEditor.name = data
        },
        font (state, data) {
            this.state.fontEditor.isModified = false;
            this.state.fontEditor.letters = data
        },
        fontNames (state, data) {
            this.state.fontEditor.fontNames = data
        },
        setError (state, data) {
            this.state.fontEditor.error.has = true
            this.state.fontEditor.error.message = data
        },
        clearError (state) {
            state.error.has = false
            state.error.message = ''
        }
    }
}

const cuEditor = {
    namespaced: true,
    state: {
        language: '',
        languages: [],
        cuNumber: null,
        replacements: [],
        replFilename: '',
    },
    getters: {
    },
    mutations: {
        language (state, data) {
            this.state.cuEditor.language = data
        },
        languages (state, data) {
            this.state.cuEditor.languages = data
        },
        cuNumber (state, data) {
            this.state.cuEditor.cuNumber = data;
        },
        replacements (state, data) {
            this.state.cuEditor.replacements = data.replacements;
            this.state.cuEditor.replFilename = data.filename;
        },
    },
    actions: {
        loadLanguages: function (context) {
            axios.get(config.api + config.settingsPath)
                .then( response => context.commit('languages', response.data) )
                .catch( response => { console.log(response); } )
        },
        getReplacements: function (context, cuNumber)  {
            context.commit('cuNumber', cuNumber);
            axios.get(config.api + config.contractionsPath + "\\" + this.state.cuEditor.language + "\\" + this.state.cuEditor.cuNumber)
                .then( response => {
                    if (response.data != undefined && response.data.length == 0) {
                        let defaultRes = {'filename': '', 'replacements': []};
                        context.commit('replacements', defaultRes)
                    } else {
                        context.commit('replacements', response.data)
                    }

                })
                .catch( response => { console.log(response); } )
        },
        saveReplacements: function (context)  {
            axios.post(config.api + config.contractionsPath + "\\" + this.state.cuEditor.language + "\\" + this.state.cuEditor.cuNumber, this.state.cuEditor.replacements)
                .then( response => {
                    if (response.data.isArray) {
                        context.commit('replacements', response.data)
                    }
                    toast.success('Ersetzungen wurde gespeichert'); //{'Speichern');
                })
                // eslint-disable-next-line no-unused-vars
                .catch( response => {
                    toast.danger('Speichern fehlgeschlagen, ein unbekannter Fehler ist aufgetreten.'); // 'Speichern');
                })
        }
    }
}

// https://vuex.vuejs.org/guide/modules.html#module-local-state
export default new Vuex.Store({
    modules: {
        fontEditor: fontEditor,
        cuEditor: cuEditor
    },
    state: {
        alertMessage: '',
        alertState: false,
        collapseMenu: true,
        documentId: '',
        documentName: '',
        previewDocumentId: '',
        previewBRL: '',
        shouldExecuteBRLWatcher: true,
        hasTextPreview: false,
        documentType: 'Braille',
        documentUpdate: 0,
        formPageLayout: {},
        formBrailleCoding: {},
        languages: {},
        auth: {
            user: null,
            token: null
        }
    },
    actions: {
        documentUpdate (context, payload) {
            context.commit("documentUpdate", payload);
        },
        formPageLayout (context, payload) {
            context.commit("formPageLayout", payload);
        },
        formBrailleCoding (context, payload) {
            context.commit("formBrailleCoding", payload);
        },
        setDocumentId (context, payload) {
            context.commit("documentId", payload);
        },
        setDocumentName (context, payload) {
            context.commit("documentName", payload);
        },
        setPreviewDocumentId (context, payload) {
            context.commit("previewDocumentId", payload);
        },
        setHasTextPreview (context, payload) {
            context.commit("hasTextPreview", payload == true);
        },
        setPreviewBRL (context, payload) {
            context.commit("previewBRL", payload);
        },
        setDocumentType (context, payload) {
            context.commit("documentType", payload);
        },
        alertState (context, payload) {
            context.commit("alertState", payload);
        },
        setCollapseMenu (context, payload) {
            context.commit("collapseMenu", payload == true);
        },
        submitPageLayout: function (context, payload) {
            payload.form.marginLeft = payload.form.marginBackRight;
            payload.form.marginRight = payload.form.marginBackLeft;
            payload.form.marginTop = payload.form.marginBackTop;
            payload.form.marginBottom = payload.form.marginBackBottom;
            context.commit('formPageLayout', payload.form);
            context.dispatch('sendForms')
        },
        submitBrailleCoding (context, payload) {
            context.commit('formBrailleCoding', payload.form);
            context.dispatch('sendForms')
        },
        sendForms (context, payload = null) {
            let data = Object.assign({}, this.state.formBrailleCoding, this.state.formPageLayout, {source: payload});
            axios
                .patch(config.api + config.documentPath + '/' + this.state.documentId, data)
                .then( response => {
                    this.state.shouldExecuteBRLWatcher = false;
                    this.dispatch("setPreviewBRL", response.data.source);
                    this.state.shouldExecuteBRLWatcher = true;
                    this.commit("documentUpdate");
                    this.dispatch("alertState", "Fehler: " + response.data.errMsg);
                } )
                .catch( response => { console.log(response) } );
        },
        loadLanguages: function (context) {
            axios.get(config.api + config.settingsPath)
                .then( response => context.commit('languages', response.data) )
                .catch( response => { console.log(response); console.log("YES"); } )
        },
        newDocument (context) {
            context.commit('formBrailleCoding', {});
            context.commit('formPageLayout', {});
            context.commit('documentId', '');
            context.commit('documentType', 'Braille');
            this.state.alertState = false;
        },
        async LogIn({commit}, user) {
            await axios.post(config.api + "/user/login", user).then(response => commit('setUser', response.data));
        },
        async LogOut({ commit }) {
            let user = {
                user: null,
                token: null
            };
            commit("logout", user);
        },
    },
    mutations: {
        languages (state, data) {
            this.state.languages = data
            // TODO: set default lang
            this.state.formBrailleCoding.language = 'DEUTSCH';
        },
        formBrailleCoding (state, data) {
            this.state.formBrailleCoding = data
        },
        formPageLayout (state, data) {
            this.state.formPageLayout = data
        },
        documentId (state, data) {
            this.state.documentId = data
        },
        documentType (state, data) {
            this.state.documentType = data
        },
        previewDocumentId (state, data) {
            this.state.previewDocumentId = data
        },
        previewBRL (state, data) {
            this.state.previewBRL = data
        },
        documentName (state, data) {
            this.state.documentName = data
        },
        hasTextPreview (state, data) {
            this.state.hasTextPreview = data
        },
        collapseMenu (state, data) {
            this.state.collapseMenu = data
        },
        documentUpdate (state) {
            state.documentUpdate++;
        },
        alertState (state, data) {
            this.state.alertMessage = data
            this.state.alertState = true
        },
        setUser(state, data) {
            state.auth.user = data.user;
            state.auth.token = data.token;
        },
        logout(state, user) {
            state.auth = user;
        },
    },
    getters: {
        setUser(state, username){
            state.auth = username
        },
        LogOut(state){
            state.auth = {
                user: null,
                token: null
            }
        },
        isAuthenticated: state => !!state.auth.token,
        StateUser: state => state.user,
    }
})