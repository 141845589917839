<template>
  <b-row style="margin-left:0; margin-right: 0;">
    <b-col v-if="code" class="content-container" :class="{ 'col-xs-6 col-md-6 col-lg-6': code, 'col-xs-12 col-md-12 col-lg-12': !code }">
      <codemirror
        v-if="code"
        v-model="code"
        placeholder="Code goes here..."
        :style="{ height: '100%' }"
        :autofocus="true"
        :indent-with-tab="true"
        :tab-size="2"
        :extensions="extensions"
        @ready="handleReady"
        @change="log('change', $event)"
        @focus="log('focus', $event)"
        @blur="log('blur', $event)"
      />
    </b-col>
    <b-col :key="documentUpdate" class="content-container" :class="{ 'col-xs-6 col-md-6 col-lg-6': code, 'col-xs-12 col-md-12 col-lg-12': !code }">
      <object id="braille-image" :data="getDocumentUrl()" type="application/pdf" />
    </b-col>
  </b-row>
</template>

<script>
    import {mapState, useStore} from "vuex";
    import {config} from "../config";
    import { Codemirror } from 'vue-codemirror'
    import {onMounted, ref, shallowRef, watch} from "vue";
    //import {javascript} from "@codemirror/lang-javascript";

    export default {
     name: "PreviewPdf",
     components: {Codemirror},
     setup() {
      const code = ref(null)
      const store = useStore();
      const extensions = [] //[javascript()]
      let debounce;

      // Codemirror EditorView instance ref
      const view = shallowRef()
      const handleReady = (payload) => {
       view.value = payload.view
      }

      onMounted(() => {
       code.value = store.state.previewBRL;
      });

      watch(() => store.state.previewBRL, (newVal) => {
       if (store.state.shouldExecuteBRLWatcher) {
        code.value = newVal;
       }
      })
      watch(code, changedBrl => {
       clearTimeout(debounce);
       debounce = setTimeout(() => {
        if(changedBrl) {
         store.dispatch('sendForms', changedBrl);
        }
       }, 2000);
      })

      return {
       code,
       extensions,
       handleReady,
       log: console.log
      }
     },
     computed: {
      ...mapState(['documentId', 'documentUpdate', 'previewBRL', 'collapseMenu', 'shouldExecuteBRLWatcher'])
     },
     methods: {
      getDocumentUrl() {
       return config.api + config.documentPath + '/' + this.$store.state.documentId + '?type=' + this.$store.state.documentType
      },
     },
    }
</script>

<style scoped>
  .content-container {
   height: calc(100vh - 56px);
  }

  .content-container object {
    width: 100%;
    height: calc(100vh - 63px);
  }

</style>