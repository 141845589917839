<template>
  <div v-if="showLetter" class="letter">
    <span class="letterHex">0x{{ getKeyAsHex() }}</span>
    <span class="letterSymbol">{{ getLetter() }}</span>
    <Braille :letter-key="letterKey" />
    <Attribute v-if="!isSpecial()" :letter-key="letterKey" :attribute="BFT_ATTR_CAPITAL" />
    <Attribute v-if="!isSpecial()" :letter-key="letterKey" :attribute="BFT_ATTR_SMALLETTER" />
    <Attribute v-if="!isSpecial()" :letter-key="letterKey" :attribute="BFT_ATTR_NUMDIGIT" />
    <Attribute v-if="!isSpecial()" :letter-key="letterKey" :attribute="BFT_ATTR_MATHSIGN" />
    <Attribute v-if="!isSpecial()" :letter-key="letterKey" :attribute="BFT_ATTR_PUNCTATION" />
    <span v-if="isSpecial()" :letter-key="letterKey" class="special-letter-title">{{ getSpecialLetter().title }}</span>
  </div>
  <div v-else-if="!showLetter" class="letter" />
</template>

<script>
    import {mapState} from "vuex";
    import Attribute from "@/pages/bfteditor/components/Attribute";
    import {BFT_ATTR_PUNCTATION, BFT_ATTR_NUMDIGIT, BFT_ATTR_MATHSIGN, BFT_ATTR_CAPITAL, BFT_ATTR_SMALLETTER} from "../../../store/store";
    import Braille from "@/pages/bfteditor/components/Braille";

    export default {
     name: "Letter",
     components: {
      Braille,
      Attribute
     },
     props: {
      showLetter: {
       type: Boolean,
       default: false
      },
      letterKey: {
       type: Number,
       default: 0
      }
     },
     data() {
      return {
       cols: 3,
       BFT_ATTR_PUNCTATION, BFT_ATTR_NUMDIGIT, BFT_ATTR_MATHSIGN, BFT_ATTR_CAPITAL, BFT_ATTR_SMALLETTER,
      }
     },
     computed: {
      ...mapState(['letters', 'name']),
      fontName: {
       get: function () {
        return this.$store.state.fontEditor.name
       }
      },
     },
     methods: {
      getKeyAsHex() {
       return this.letterKey.toString(16);
      },
      getValue() {
       return this.letters[this.letterKey];
      },
      getLetter() {
       return this.convertANSIToCP1256CharCode(this.letterKey);
      },
      isSpecial() {
       return this.$store.getters["fontEditor/isSpecialLetter"](this.letterKey);
       //return this.$store.getters.isSpecialLetter(this.letterKey);
      },
      getSpecialLetter() {
       return this.$store.getters["fontEditor/isSpecialLetter"](this.letterKey);
       //return this.$store.getters.getSpecialLetter(this.letterKey);
      },
      convertANSIToCP1256CharCode(ansiCharCode) {
       const iconv = require('iconv-lite')
       let encoding = 'ISO-8859-15' // für latin schriften wie deutsch, englisch

       const win1256Fonts = ['arabic', 'arabwin', 'farsi']
       const win1254Fonts = ['turkish']

       if (this.fontName !== undefined) {
        if (win1256Fonts.includes(this.fontName.toLowerCase())) {
         encoding = 'win1256';
        }
        if (win1254Fonts.includes(this.fontName.toLowerCase())) {
         encoding = 'win1254';
        }
       }

       return iconv.decode(Buffer.from([ansiCharCode]), encoding)
      }
     }
    }
</script>

<style scoped>
  .row {
    margin: 5px;
  }
  .letter {
    display: table;
    padding: 3px;
    border: #0087b8 1px solid;
    background-color: rgba(0, 135, 184, 0.16);
    width: 100%;
    height: 100%;
    border-top-width: 0px;
    border-left-width: 0px;
  }

  .letter span {
    display: table-cell;
    vertical-align: middle;
    padding: 2px;
  }

  .letter span.special-letter-title {
    font-size: 14px;
    text-align: left;
  }

  .letter .letterHex {
    width: 44px;
    padding-right: 3px;
    font-size: 14px;
  }

  .letter .letterSymbol {
    width: 20px;
    padding-right: 3px;
    text-align: center;
  }

  .braille {
    display: inline-block;
  }
</style>