<template>
  <b-modal id="braille-or-text-preview-modal" ref="ModalBrailleOrTextPreview" hide-footer hide-header>
    <div class="d-block">
      <b-container>
        <b-row>
          <div class="offset-2 col-sm-5">
            <b-form-group v-slot="{ ariaDescribedby }" label="Zielausgabe">
              <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="Braille">
                Braille
              </b-form-radio>
              <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="PDFUA">
                PDF/UA
              </b-form-radio>
              <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="PDFUA-BDM">
                PDF/UA + Braille BDM
              </b-form-radio>
            </b-form-group>
          </div>
        </b-row>
        <b-row>
          <div class="offset-2 col-sm-5">
            <b-button
              type="submit"
              variant="primary"
              @click="show()"
            >
              {{ getSelected() }} generieren
            </b-button>
          </div>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>
<script>
    import {useModal} from "bootstrap-vue-next";

    export default {
     name: 'ModalBrailleOrTextPreview',
     components: {

     },
     data() {
      return {
       selected: 'Braille'
      }
     },
     methods: {
      getSelected() {
       let selectionName = 'Braille'
       if (this.selected == 'PDFUA') {
        selectionName = 'PDF/UA';
       }
       if (this.selected == 'PDFUA-BDM') {
        selectionName = 'PDF/UA + BMD';
       }
       return selectionName;
      },
      show() {
       this.$store.dispatch("setDocumentType", this.selected)
       this.$store.dispatch("setDocumentId", this.$store.state.previewDocumentId)
       if (this.selected !== 'Braille') {
        this.$store.dispatch("setPreviewBRL", null)
       }
       useModal('braille-or-text-preview-modal').hide()
      },
     }
    }

</script>

<style></style>