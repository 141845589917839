<template>
  <VueClipZwei
    ref="vc" :options="options" :on-complete="complete"
    :on-sending="sending" class="uploader-container" :uploader-class="'uploader-container'"
  >
    <template #clip-uploader-action>
      <div>
        <div class="dz-message">
          <span>Klicken oder ziehen um Datei hochzuladen</span>
        </div>
      </div>
    </template>
    <template #clip-uploader-body="props">
      <div v-if="props.files[0]" class="uploaded-file-list">
        <fe-document aria-hidden="true" scale="1.25" />
        {{ props.files[0].name }} {{ props.files[0].status }}
      </div>
    </template>
  </VueClipZwei>
</template>

<script>
    import FeDocument from '@/components/icons/FeDocument.vue'
    import {config} from "@/config";
    import {useModal} from "bootstrap-vue-next";
    
    export default {
     name: 'Upload',
     components: {
      FeDocument
     },
     data() {
      return {
       options: {
        url: config.api + config.documentPath,
        paramName: 'datei',
        maxFiles: 1,
       }
      }
     },
     computed: {
      form: {
       get() {
        return this.$store.state.form;
       }
      }
     },
     methods: {
      /**
      * Start upload
      * @param file
      * @param xhr
      * @param formData
      */
      sending (file, xhr, formData) {
       formData.append('settings', JSON.stringify(this.form))
      },
      /**
      * File was sent
      * @param file
      * @param status
      * @param xhr
      */
      async complete (file, status, xhr) {
       try {
        if (status !== 'success') {
         throw new Error();
        }

        const result = JSON.parse(xhr.response);

        console.log(result);
        if (result.debug != null) {
         console.log('host debug info: ' + result.debug);
        }

        if (result.success === false) {
         throw new Error(result.errMsg);
        }

        const { dispatch } = this.$store;

        await Promise.all([
         dispatch('setHasTextPreview', result.hasTextPreview),
         dispatch('setDocumentName', result.name),
         dispatch('setPreviewBRL', result.source),
         result.hasTextPreview ? dispatch('setPreviewDocumentId', result.documentId) : dispatch('setDocumentId', result.documentId)
        ]);

        if (result.hasTextPreview) {
         const selectionModal = useModal('braille-or-text-preview-modal');
         selectionModal.show();
        }
       } catch (error) {
        let message = error.message || 'Ein unbekannter Fehler ist aufgetreten.';
        this.$store.dispatch('alertState', message);
        this.$refs.vc.removeAllFiles(false);
       }
       this.get
      }
     }

    }
</script>

<style>
  .uploader-container {
    width: 350px;
    margin: auto;
  }

  .dz-message {
    /* border: 1px solid #B5E3F0; */
    /* background-color: #4fc0e2; */
    background-color: #0087b8;
    width: 350px;
    padding: 10px !important;
    border-radius: 10px;
  }
  .dz-message span {
    font-size: 1em;
    color: #fff;
    padding: 10px;
    border: 2px dashed #3b556f73;
    display: block;
    border-radius: 10px;
  }

  .uploaded-file-list {
    padding: 10px;
    background-color: #4fc0e294;
  }
</style>