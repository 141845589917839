<template>
  <b-card-text>
    <PageLayoutSettingsForm />
  </b-card-text>
</template>

<script>
    import PageLayoutSettingsForm from "./forms/PageLayoutSettingsForm";
    export default {
     name: "PageLayoutSettings",
     components: {PageLayoutSettingsForm}
    }
</script>

<style scoped>
</style>