<template>
  <div id="app">
    <ModalBrailleOrTextPreview />
    <b-container fluid>
      <b-row>
        <b-col cols="12" lg="12" md="12">
          <Navbar />
        </b-col>
      </b-row>
      <b-row class="">
        <b-nav
          v-if="getCollapseMenu()" vertical style="float:left; width: 50px;"
          class="sidebar-nav"
        >
          <b-nav-item v-b-tooltip.hover active title="Einstellungsmenü anzeigen" @click="toggleCollapseMenu()">
            <fe-arrow-right variant="info" />
          </b-nav-item>
        </b-nav>
        <b-col
          v-if="!getCollapseMenu()" id="test" cols="3" lg="3"
          md="3"
        >
          <SettingsColumn />
        </b-col>
        <b-col v-if="!isPDF()">
          <Preview />
        </b-col>
        <b-col v-if="isPDF()" :class="{'col-xs-9 col-md-9 col-lg-9': !getCollapseMenu()}" :style="{ width: getCollapseMenu() ? 'calc(100% - 56px) !important' : '' }">
          <PreviewPdf />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
    import Navbar from "./components/Navbar";
    import SettingsColumn from "./components/SettingsColumn";
    import Preview from "./components/Preview";
    import PreviewPdf from "./components/PreviewPdf";
    import {mapState} from 'vuex'
    import ModalBrailleOrTextPreview from "@/components/modals/ModalBrailleOrTextPreview";
    import FeArrowRight from '@/components/icons/FeArrowRight.vue'

    export default {
     name: 'Main',
     components: {
      ModalBrailleOrTextPreview,
      Preview,
      PreviewPdf,
      SettingsColumn,
      Navbar,
      FeArrowRight
     },
     computed: {
      ...mapState(['documentId', 'collapseMenu'])
     },
     methods: {
      isPDF() {
       return this.documentId != ''
      },
      toggleCollapseMenu() {
       this.$store.commit('collapseMenu', !this.collapseMenu);
      },
      getCollapseMenu() {
       return this.collapseMenu;
      }
     },
    }
</script>

<style>
#app {
 font-family: 'Avenir', Helvetica, Arial, sans-serif;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 color: #2c3e50;
 height: 100%;
}

.card-header ul {
 margin-left: 10px !important;
 margin-right: 10px !important;
}

.sidebar-nav {
 height: calc(100vh - 56px);
 background-color: #f7f7f7;
}

</style>
