// Importing necessary dependencies
import { ref, onMounted, h } from 'vue'
import Uploader from '../Uploader'
import File from '../File'
import clone from 'lodash.clone'

export default {


  // Defining props
  props: {
    uploaderClass: String,

    options: {
      type: Object,
      default: () => ({})
    },
    onAddedFile: {
      type: Function,
      default: () => {}
    },
    onRemovedFile: {
      type: Function,
      default: () => {}
    },
    onSending: {
      type: Function,
      default: () => {}
    },
    onDragEnter: {
      type: Function,
      default: () => {}
    },
    onDragLeave: {
      type: Function,
      default: () => {}
    },
    onDrop: {
      type: Function,
      default: () => {}
    },
    onTotalProgress: {
      type: Function,
      default: () => {}
    },
    onQueueComplete: {
      type: Function,
      default: () => {}
    },
    onMaxFiles: {
      type: Function,
      default: () => {}
    },
    onInit: {
      type: Function,
      default: () => {}
    },
    onComplete: {
      type: Function,
      default: () => {}
    },
  },



  // Component data
  setup(props, {slots, expose}) {
    const files = ref([])
    const dragCounter = ref(0)
    let clipPreviewTemplateRef = ref(null);
    let firstChildRef = ref(null);
    let uploader = null

    // Lifecycle hook
    onMounted(() => {
      const options = clone(props.options)
      const accept = options.accept || function (file, done) { done() }

      // Overriding properties of the options object
      options.previewTemplate = clipPreviewTemplateRef.value.innerHTML;
      options.accept = ({ blobId }, done) => {
        accept(getFile(blobId), done)
      }

      if (typeof (options.maxFiles) !== 'undefined' && options.maxFiles instanceof Object === true) {
        const { limit, message } = options.maxFiles
        options.maxFiles = limit
        options.dictMaxFilesExceeded = message
      }

      if (typeof (options.maxFilesize) !== 'undefined' && options.maxFilesize instanceof Object === true) {
        const { limit, message } = options.maxFilesize
        options.maxFilesize = limit
        options.dictFileTooBig = message
      }

      if (typeof (options.acceptedFiles) !== 'undefined' &&
          options.acceptedFiles instanceof Object === true &&
          options.acceptedFiles instanceof Array === false) {
        const { extensions, message } = options.acceptedFiles
        options.acceptedFiles = extensions.join(',')
        options.dictInvalidFileType = message
      }

        // Instantiating uploader
      uploader = new Uploader(options)
      bindEvents()
      uploader.mount(firstChildRef.value)
      console.log(firstChildRef.value);
      props.onInit(this)

    })

    // Methods
    const bindEvents = () => {
      uploader.on('addedfile', addedFile)
      uploader.on('removedfile', removedFile)
      uploader.on('sending', sending)
      uploader.on('complete', complete)
      uploader.on('error', error)
      uploader.on('uploadprogress', uploadProgress)
      uploader.on('thumbnail', thumbnail)
      uploader.on('drop', drop)
      uploader.on('dragenter', dragEnter)
      uploader.on('dragleave', dragLeave)
      uploader.on('totaluploadprogress', totalUploadProgress)
      uploader.on('maxfilesexceeded', maxFilesExceeded)
      uploader.on('queuecomplete', queueComplete)
    }

    const getFile = (blobId) => {
      let matchedFile = {}
      files.value.forEach((file) => {
        if (file._file.blobId === blobId) {
          matchedFile = file
        }
      })
      return matchedFile
    }

    const addedFile = (file) => {
      const fileId = Symbol()
      file.blobId = fileId
      files.value.push(new File(file))
      props.onAddedFile(getFile(fileId))
    }

    const removedFile = ({ blobId }) => {
      const fileInstance = getFile(blobId)
      fileInstance.updateStatus('removed')
      props.onRemovedFile(fileInstance)
    }

    const sending = ({ blobId }, xhr, formData) => {
      const fileInstance = getFile(blobId)
      props.onSending(fileInstance, xhr, formData)
    }

    const complete = ({ blobId, status, xhr = {} }) => {
      const fileInstance = getFile(blobId)
      fileInstance.updateStatus(status)
      fileInstance.updateXhrResponse({
        response: xhr.response,
        responseText: xhr.responseText,
        statusCode: xhr.status
      })
      props.onComplete(fileInstance, status, xhr)
    }

    const error = ({ blobId, status }, errorMessage) => {
      const fileInstance = getFile(blobId)
      fileInstance.updateStatus(status)
      fileInstance.updateErrorMessage(errorMessage)
    }

    const uploadProgress = ({ blobId }, progress, bytesSent) => {
      const fileInstance = getFile(blobId)
      fileInstance.updateProgress(progress)
      fileInstance.updateBytesSent(bytesSent)
    }

    const thumbnail = ({ blobId }, dataUrl) => {
      const fileInstance = getFile(blobId)
      fileInstance.updateDataUrl(dataUrl)
    }

    const drop = () => {
      dragCounter.value = 0
      props.onDrop()
      props.onDragLeave()
    }

    const dragEnter = (event) => {
      event.preventDefault()
      dragCounter.value++
      props.onDragEnter()
    }

    const dragLeave = () => {
      dragCounter.value--
      if (dragCounter.value === 0) {
        props.onDragLeave()
      }
    }

    const totalUploadProgress = (...args) => {
      props.onTotalProgress(...args)
    }

    const queueComplete = () => {
      props.onQueueComplete()
    }

    const maxFilesExceeded = ({ blobId }) => {
      const fileInstance = getFile(blobId)
      props.onMaxFiles(fileInstance)
    }

    const removeFile = (file) => {
      uploader.removeFile(file._file)
    }

    const addFile = (file) => {
      uploader.addFile(file)
    }

    const removeAllFiles = (cancelQueued) => {
      uploader.removeAllFiles(cancelQueued)
    }

    expose( {files,
      dragCounter,
      uploader,
      bindEvents,
      getFile,
      addedFile,
      removedFile,
      sending,
      complete,
      error,
      uploadProgress,
      thumbnail,
      drop,
      dragEnter,
      dragLeave,
      totalUploadProgress,
      queueComplete,
      maxFilesExceeded,
      removeFile,
      addFile,
      removeAllFiles})

    return () => h('div', { class: props.uploaderClass, style: 'color:green;',ref: firstChildRef }, [
      slots['clip-uploader-action']({ dragging: dragCounter.value > 0 }),
      slots['clip-uploader-body']({ files: files.value }),
      h('div', { ref: clipPreviewTemplateRef, class: 'clip-preview-template', style: 'display: none;' }, [h('div')]),
    ]);
  },
}
