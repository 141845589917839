<template>
  <div role="tablist" className="settingsCol settingsCol-bft-editor">
    <ModalSelectFont />
    <ModalNewFont />
    <ModalCUEditor />
    <b-card no-body class-name="mb-1">
      <b-tabs pills card class-name="tabs-list">
        <b-tab title="Datei" active>
          <b-card-text>
            <b-container class="settings-container">
              <h5>Braille</h5>
              <b-button class="btn-block" variant="primary" @click="showNewFontModal()">
                Neu
              </b-button>
              <b-button class="btn-block" variant="primary" @click="showSelectFontModal()">
                Vorhandene Font öffnen
              </b-button>
              <br>
              <b-button class="btn-block" variant="success" :disabled="!isModified" @click="saveFont();">
                Speichern
              </b-button>
              <b-button class="btn-block" variant="danger" :disabled="!isModified" @click="loadFont();">
                Zurücksetzen
              </b-button>
              <br>
              <br>
              <h5>Ersetzungsbibliothek</h5>
              <h6>Sprache</h6>
              <b-form-select
                v-model="language" name="braille" :value="language"
                class="form-control"
                size="sm"
              >
                <option v-for="x in languages" :key="x.id" :value="x.id">
                  {{ x.name }}
                </option>
              </b-form-select>
              <br>
              <br>
              <b-button class="btn-block" variant="secondary" :disabled="!language.length" @click="showCUEditorModal(1)">
                Vollschrift-Ersetzungen
              </b-button>
              <b-button class="btn-block" variant="secondary" :disabled="!language.length" @click="showCUEditorModal(2)">
                Kurzschrift-Ersetzungen
              </b-button>
              <b-button class="btn-block" variant="secondary" disabled="disabled" @click="showCUEditorModal(3)">
                Ausnahmenbibliothek
              </b-button>
            </b-container>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
    import ModalSelectFont from "@/pages/bfteditor/components/ModalSelectFont";
    import ModalNewFont from "@/pages/bfteditor/components/ModalNewFont";
    import ModalCUEditor from "@/pages/bfteditor/components/ModalCUEditor";
    import {mapState, mapActions} from "vuex";
    import {useModal} from "bootstrap-vue-next";

    export default {
     name: "SettingsColumnBFTEditor",
     components: {
      ModalSelectFont,
      ModalNewFont,
      ModalCUEditor
     },
     data() {
      return {
      }
     },
     computed: {
      language: {
       get:  function() {
        return this.$store.state.cuEditor.language
       },
       set: function(value) {
        this.$store.commit('cuEditor/language', value)
       }
      },
      ...mapState('cuEditor', ['languages']),
      ...mapState('fontEditor', ['isModified'])
     },
     mounted() {
      this.loadLanguages();
     },
     methods: {
      showCUEditorModal(cuNumber) {
       this.getReplacements(cuNumber);
       useModal('cu-editor-modal').show();
      },
      showNewFontModal() {
       useModal('new-font-modal').show()
      },
      showSelectFontModal() {
       useModal('select-font-modal').show()
      },
      ...mapActions('fontEditor', ['loadFont', 'saveFont', 'loadFontList']),
      ...mapActions('cuEditor', ['loadLanguages', 'getReplacements'])
     }
    }
</script>

<style scoped>

    .settings-container {
      padding: 15px;
      height: calc(100vh - 127px);
      overflow-y: scroll;
}
    .card-body {
    padding-top: 0px;
    padding-bottom: 0px;
}

    .card {
    border-radius: unset;
}

    .tab-container {
    overflow-y: auto;
    height: calc(100vh - 150px);
}

    .tab-container.spaceholder {
    margin-bottom: 10px;
    margin-top: 10px;
}
</style>