<template>
  <div class="page-layout-settings-container tab-container">
    <b-form
      v-if="show"
      class="global-settings-form"
      @reset="onReset"
      @submit.prevent="submitPageLayout($data)"
    >
      <b-form-group
        id="input-group-paper"
        label="Papierformat:"
        label-for="field-paper"
        label-size="sm"
      >
        <b-form-select
          id="field-paper"
          v-model="form.paper"
          :options="papers"
          required
          size="sm"
        />
      </b-form-group>
      <b-form-group
        v-if="form.paper === PaperFormat.CUSTOM"
        id="input-group-paper-format-custom"
        label="Benutzerdefiniertes Papierformat (Zoll):"
        label-for="field-printer-mode"
        label-size="sm"
        class="mb-0"
      >
        <b-form-group
          id="input-group-paper-height"
          label="Höhe:"
          label-for="field-paper-height"
          label-size="sm"
          label-align-sm="right"
          label-cols-sm="3"
        >
          <b-form-spinbutton
            id="field-paper-height"
            v-model="form.paperHeight"
            type="number"
            required
            size="sm"
            inline
          />
          <b-form-invalid-feedback :state="paperHeightValidation">
            Länge darf max. 12 Zoll sein.
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="input-group-paper-width"
          label="Breite:"
          label-for="field-paper-height"
          label-size="sm"
          label-align-sm="right"
          label-cols-sm="3"
        >
          <b-form-spinbutton
            id="field-paper-width"
            v-model="form.paperWidth"
            type="number"
            value="12"
            required
            size="sm"
            inline
          />
          <b-form-invalid-feedback :state="paperWidthValidation">
            Breite darf max. 12 Zoll sein.
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form-group>

      <b-form-group
        id="input-group-printer-mode"
        label="Druckmodus:"
        label-for="field-printer-mode"
        label-size="sm"
      >
        <b-form-select
          id="field-printer-mode"
          v-model="form.printerMode"
          :options="printermodes"
          required
          size="sm"
        />
        <b-form-text id="printer-mode-help-block">
          Einstellung ist Druckerabhängig
        </b-form-text>
      </b-form-group>
      <b-form-row class="no-margin-row">
        <b-col sm="12" xs="12" lg="6" md="12">
          <b-form-group
            id="input-group-margins"
            label="Seitenrand Vorderseite:"
            label-for="field-printer-mode"
            label-size="sm"
            class="mb-0 form-group"
          >
            <b-form-group
              id="input-group-margin-left"
              label="Links"
              label-for="field-margin-left"
              label-size="sm"
              label-align-sm="right"
              label-cols-sm="4"
              class="form-group"
            >
              <b-form-spinbutton
                id="field-margin-left"
                type="number"
                required
                placeholder="In Zellen"
                size="sm"
                min="1"
                max="4"
                inline
                :value="form.marginLeft"
                @input="value => { form.marginBackRight = value }"
              />
            </b-form-group>
            <b-form-group
              id="input-group-margin-right"
              label="Rechts"
              label-for="field-margin-right"
              label-size="sm"
              label-align-sm="right"
              label-cols-sm="4"
              class="form-group"
            >
              <b-form-spinbutton
                id="field-margin-right"
                type="number"
                required
                placeholder="In Zellen"
                size="sm"
                min="1"
                max="4"
                inline
                :value="form.marginRight"
                @input="value => { form.marginBackLeft = value }"
              />
            </b-form-group>
            <b-form-group
              id="input-group-margin-top"
              label="Oben"
              label-for="field-margin-top"
              label-size="sm"
              label-align-sm="right"
              label-cols-sm="4"
              class="form-group"
            >
              <b-form-spinbutton
                id="field-margin-top"
                v-model="form.marginTop"
                type="number"
                required
                placeholder="In Punktreihen"
                size="sm"
                min="1"
                max="3"
                inline
                :value="form.marginTop"
                @input="value => { form.marginBackTop = value }"
              />
            </b-form-group>
            <b-form-group
              id="input-group-margin-bottom"
              label="Unten"
              label-for="field-margin-bottom"
              label-size="sm"
              label-align-sm="right"
              label-cols-sm="4"
              class="form-group"
            >
              <b-form-spinbutton
                id="field-margin-right"
                v-model="form.marginBottom"
                type="number"
                required
                placeholder="In Punktreihen"
                size="sm"
                min="1"
                max="3"
                inline
                :value="form.marginBottom"
                @input="value => { form.marginBackBottom = value }"
              />
            </b-form-group>
          </b-form-group>
        </b-col>
        <b-col sm="12" xs="12" lg="6" md="12">
          <b-form-group
            id="input-group-back-margins"
            label="Rückseite:"
            label-for="field-printer-mode"
            label-size="sm"
            class="mb-0 form-group"
          >
            <b-form-group
              id="input-group-back-margin-right"
              label="Rechts"
              label-for="field-margin-right"
              label-size="sm"
              label-align-sm="right"
              label-cols-sm="4"
              class="form-group"
            >
              <b-form-spinbutton
                id="field-margin-right"
                v-model="form.marginBackRight"
                :disabled="form.printerMode === PrinterMode.SINGLE_SIDE"
                type="number"
                required
                placeholder="In Zellen"
                size="sm"
                min="1"
                max="4"
                inline
              />
            </b-form-group>
            <b-form-group
              id="input-group-back-margin-left"
              label="Links"
              label-for="field-back-margin-left"
              label-size="sm"
              label-align-sm="right"
              label-cols-sm="4"
              class="form-group"
            >
              <b-form-spinbutton
                id="field-back-margin-left"
                v-model="form.marginBackLeft"
                type="number"
                required
                placeholder="In Zellen"
                size="sm"
                min="1"
                max="4"
                inline
                :disabled="form.printerMode === PrinterMode.SINGLE_SIDE"
              />
            </b-form-group>
            <b-form-group
              id="input-group-back-margin-top"
              label="Oben"
              label-for="field-margin-top"
              label-size="sm"
              label-align-sm="right"
              label-cols-sm="4"
              class="form-group"
            >
              <b-form-spinbutton
                id="field-margin-top"
                v-model="form.marginBackTop"
                type="number"
                required
                placeholder="In Punktreihen"
                size="sm"
                min="1"
                max="3"
                inline
                :disabled="form.printerMode === PrinterMode.SINGLE_SIDE"
              />
            </b-form-group>
            <b-form-group
              id="input-group-back-margin-bottom"
              label="Unten"
              label-for="field-margin-bottom"
              label-size="sm"
              label-align-sm="right"
              label-cols-sm="4"
              class="form-group"
            >
              <b-form-spinbutton
                id="field-back-margin-right"
                v-model="form.marginBackBottom"
                type="number"
                required
                placeholder="In Punktreihen"
                size="sm"
                min="1"
                max="3"
                inline
                :disabled="form.printerMode === PrinterMode.SINGLE_SIDE"
              />
            </b-form-group>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row />
      <hr>
      <b-form-row class="no-margin-row">
        <b-col sm="12" lg="12" md="12">
          <b-form-checkbox
            id="field-header" v-model="form.header" name="check-button" size="sm"
            switch
          >
            Header
          </b-form-checkbox>
          <b-form-group
            id="input-group-header-text"
          >
            <b-form-input
              id="field-header-text"
              v-model="form.headerText"
              placeholder="Benutzerdefinierter Headertext"
              :required="form.header === true"
              :disabled="form.header === false"
              size="sm"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" lg="12" md="12">
          <b-form-checkbox
            id="field-footer" v-model="form.footer" name="check-button" size="sm"
            switch
          >
            Footer
          </b-form-checkbox>
          <b-form-group
            id="input-group-footer-text"
          >
            <b-form-input
              id="field-footer-text"
              v-model="form.footerText"
              placeholder="Benutzerdefinierter Footertext"
              :required="form.footer === true"
              :disabled="form.footer === false"
              size="sm"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <hr>
      <b-form-checkbox
        id="field-page-numbering" v-model="form.pageNumbering" name="check-button" size="sm"
        switch
      >
        Seitennummerierung
      </b-form-checkbox>
      <b-form-row class="no-margin-row">
        <b-col sm="6">
          <b-form-group
            id="input-group-numbering-left-position"
            label="Vorderseite:"
            label-for="field-numbering-left-position"
            label-size="sm"
          >
            <b-form-select
              id="field-numbering-left-position"
              v-model="form.numberingLeftPosition"
              :options="numberingPositions"
              required
              size="sm"
              :disabled="form.pageNumbering === false"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            id="input-group-numbering-right-position"
            label="Rückseite:"
            label-for="field-numbering-right-position"
            label-size="sm"
          >
            <b-form-select
              id="field-numbering-right-position"
              v-model="form.numberingRightPosition"
              :options="numberingPositions"
              min="1"
              max="4"
              required
              size="sm"
              :disabled="form.pageNumbering === false || form.printerMode === PrinterMode.SINGLE_SIDE"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row class="no-margin-row">
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-space"
            label="Platzhalter:"
            label-for="field-space"
            label-size="sm"
            class="mb-0 form-group"
            description="Zellen vertikal"
          >
            <b-form-spinbutton
              id="field-space"
              v-model="form.space"
              max="5"
              min="1"
              type="number"
              value="1"
              required
              placeholder=""
              size="sm"
              inline
              :disabled="form.pageNumbering === false"
            />
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6">
          <b-form-group
            id="input-group-pagination-start"
            label="Beginne mit Zahl:"
            label-for="field-pagination-start"
            label-size="sm"
            class="mb-0 form-group"
            description="Seitennummerierung"
          >
            <b-form-spinbutton
              id="field-space"
              v-model="form.paginationStart"
              type="number"
              required
              placeholder=""
              size="sm"
              inline
              :disabled="form.pageNumbering === false"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-group
        id="input-group-number-format"
        label="Zahlenformat:"
        label-for="field-number-format"
        label-size="sm"
        class="form-group"
      >
        <b-form-select
          id="field-number-format"
          v-model="form.numberFormat"
          :options="numberFormats"
          required
          size="sm"
        />
        <b-form-text
          v-if="form.numberFormat === NumberFormat.ARABIC"
          id="number-format-help-block"
        >
          Reguläre Braille-Zahlen mit Zahlvorzeichen
        </b-form-text>
        <b-form-text
          v-if="form.numberFormat === NumberFormat.ARABIC_SUB"
          id="number-format-help-block"
        >
          Reguläre Braille-Zahlen mit Zahlvorzeichen, jedoch eine Punktreihe tiefer als Arabisch
        </b-form-text>
        <b-form-text
          v-if="form.numberFormat === NumberFormat.ROMIAN"
          id="number-format-help-block"
        >
          Römische Zahlen ohne Zahlvorzeichen
        </b-form-text>
      </b-form-group>

      <b-button
        type="submit"
        variant="primary"
      >
        Übernehmen
      </b-button>&nbsp;
      <b-button
        type="reset"
        variant="danger"
      >
        Zurücksetzen
      </b-button>
    </b-form>
    <div
      class="mt-3"
      style="display: none;"
    >
      <pre class="m-0">{{ form }}</pre>
    </div>
  </div>
</template>

<script>
    import {mapActions} from 'vuex'

    const NumberFormat = {
     ARABIC: 0,
     ARABIC_SUB: 1,
     ROMIAN: 2,
    };

    const PrinterMode = {
     SINGLE_SIDE: 0,
     INTERPOINT: 1
    };

    const PaperFormat = {
     A4: 0,
     SIZE12: 1,
     CUSTOM: 2
    }

    const NumberingPosition = {
     TOPRIGHT: 0,
     TOPLEFT: 1,
     BOTTOMRIGHT: 2,
     BOTTOMLEFT: 3,
    }

    const HeaderFooterSettings = {
     T1: 0,
     T2: 1,
     T3: 2,
     T4: 3,
     T5: 4,
     T6: 5,
     CUSTOM: -1
    }

    export default {
     name: 'PageLayoutSettingsForm',
     data() {
      return {
       form: {
        paper: PaperFormat.A4,
        paperHeight: 12,
        paperWidth: 12,
        printerMode: PrinterMode.SINGLE_SIDE,
        marginLeft: 1,
        marginRight: 1,
        marginTop: 1,
        marginBottom: 1,
        marginBackLeft: 1,
        marginBackRight: 1,
        marginBackTop: 1,
        marginBackBottom: 1,
        headerHeight: 4,
        headerMargin: 4,
        footerHeight: 4,
        footerMargin: 4,
        pageNumbering: true,
        numberingLeftPosition: NumberingPosition.BOTTOMLEFT,
        numberingRightPosition: NumberingPosition.BOTTOMRIGHT,
        space: 5,
        paginationStart: 1,
        numberFormat: NumberFormat.ARABIC,
        header: false,
        headerSetting: HeaderFooterSettings.T1,
        headerText: '',
        footer: false,
        footerSetting: HeaderFooterSettings.T1,
        footerText: '',
       },
       numberingPositions: [
        {text: 'Oben Rechts', value: NumberingPosition.TOPRIGHT},
        {text: 'Oben Links', value: NumberingPosition.TOPLEFT},
        {text: 'Unten Rechts', value: NumberingPosition.BOTTOMRIGHT},
        {text: 'Unten Links', value: NumberingPosition.BOTTOMLEFT},
       ],
       headerSettings: [
        {text: 'Titel 1', value: HeaderFooterSettings.T1},
        {text: 'Titel 2', value: HeaderFooterSettings.T2},
        {text: 'Titel 3', value: HeaderFooterSettings.T3},
        {text: 'Titel 4', value: HeaderFooterSettings.T4},
        {text: 'Titel 5', value: HeaderFooterSettings.T5},
        {text: 'Titel 6', value: HeaderFooterSettings.T6},
        {text: 'Benutzerdefiniert', value: HeaderFooterSettings.CUSTOM},
       ],
       footerSettings: [
        {text: 'Titel 1', value: HeaderFooterSettings.T1},
        {text: 'Titel 2', value: HeaderFooterSettings.T2},
        {text: 'Titel 3', value: HeaderFooterSettings.T3},
        {text: 'Titel 4', value: HeaderFooterSettings.T4},
        {text: 'Titel 5', value: HeaderFooterSettings.T5},
        {text: 'Titel 6', value: HeaderFooterSettings.T6},
        {text: 'Benutzerdefiniert', value: HeaderFooterSettings.CUSTOM},
       ],
       papers: [{text: 'A4 (12x8 Zoll)', value: PaperFormat.A4},
                {text: '12x12 Zoll', value: PaperFormat.SIZE12}, {
                 text: 'Benutzerdefiniert',
                 value: PaperFormat.CUSTOM
                }],
       printermodes: [{
        text: 'Einseitiger Druck',
        value: PrinterMode.SINGLE_SIDE
       }, {text: 'Doppelseitiger Druck', value: PrinterMode.INTERPOINT}],
       numberFormats: [{text: 'Arabisch', value: NumberFormat.ARABIC}, {
        text: 'Tiefergestellt',
        value: NumberFormat.ARABIC_SUB
       }, {text: 'Römisch', value: NumberFormat.ROMIAN}],
       show: true,
       PaperFormat,
       NumberFormat,
       PrinterMode,
       HeaderFooterSettings
      }
     },
     computed: {
      console: () => console,
      window: () => window,
      paperHeightValidation() {
       return this.form.paperHeight <= 12
      },
      paperWidthValidation() {
       return this.form.paperWidth <= 14
      },
     },
     created() {
      this.$store.state.formPageLayout = this.form;
     },
     methods: {
      onSubmit(evt) {
       evt.preventDefault()
      },
      onReset(evt) {
       evt.preventDefault()
       // Reset our form values
       this.form.paper = PaperFormat.A4
       this.form.paperHeight = 12
       this.form.paperWidth = 12
       this.form.printerMode = PrinterMode.SINGLE_SIDE
       this.form.marginLeft = 1
       this.form.marginRight = 1
       this.form.marginBottom = 1
       this.form.marginTop = 1
       this.form.marginBackLeft = 1
       this.form.marginBackRight = 1
       this.form.marginBackBottom = 1
       this.form.marginBackTop = 1
       this.form.headerHeight = 4
       this.form.headerMargin = 4
       this.form.footerHeight = 4
       this.form.footerMargin = 4
       this.form.fieldNumbering = true
       this.form.pageNumbering = true
       this.form.numberingLeftPosition = NumberingPosition.BOTTOMLEFT
       this.form.numberingRightPosition = NumberingPosition.BOTTOMRIGHT
       this.form.space = 1
       this.form.paginationStart = 1
       this.form.numberFormat = NumberFormat.ARABIC
       this.form.header = false
       this.form.headerSetting = HeaderFooterSettings.T1
       this.form.headerText = '';
       this.form.footer = false
       this.form.footerSetting = HeaderFooterSettings.T1
       this.form.footerText = '';

       // Trick to reset/clear native browser form validation state
       this.show = false
       this.$nextTick(() => {
        this.show = true
       })
      },
      ...mapActions({
       submitPageLayout: 'submitPageLayout'
      }),
     }
    }
</script>

<style scoped>
    .global-settings-form {
        padding: 15px;
    }

  .tab-container {
    height: calc(100vh - 127px);
    overflow-y: scroll;
  }
</style>