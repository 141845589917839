<template>
  <div class="privacy-container">
    <b-container fluid>
      <b-row>
        <b-col cols="12" lg="12" md="12">
          <Navbar />
        </b-col>
      </b-row>
      <br>
      <br>
      <b-row>
        <b-col
          cols="10" lg="6" md="6" xl="6"
          offset="1" offset-md="3" offset-lg="3" offset-xl="3"
        >
          <h2>DATENSCHUTZ</h2>

          <p>Persönliche Daten, die während des Besuches unserer Website anfallen, werden nach den Bestimmungen der EU-Datenschutz-Grundverordnung (DSGVO) behandelt.</p>

          <h4>Datenverarbeitung</h4>
          <p>Der Besuch unserer Website verwendet keine cookies und speichert keine benutzerbezogenen Daten, außer in Standard-Logdateien, die zum Betreib unserer Web-Server erforderlich sind.</p>

          <h4>Verwendung persönlicher Daten</h4>
          <p>Ihre persönlichen Daten, die Sie z.B. zur Braillekonvertierung uploaden, verwenden wir ausschließlich zur Bearbeitung Ihrer Anfrage und speichern diese Daten nur solange, wie es im jeweiligen Fall notwendig ist.</p>

          <h4>Sicherheit</h4>
          <p>Wir verwenden technische und organisationsweite Sicherheitsmaßnahmen um Ihre Daten gegen die versehentliche oder absichtliche Manipulation und den Verlust zu schützen. Unsere Sicherheitsmaßnahmen werden beständig erweitert sobald neue Technologien verfügbar werden.</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
    import Navbar from "@/components/Navbar";

    export default {
     name: "Privacy",
     components: {
      Navbar
     },
    }
</script>

<style scoped>

</style>