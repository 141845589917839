<template>
  <div class="dotcloud-app-container">
    <b-container fluid>
      <b-row>
        <b-col cols="12" lg="12" md="12">
          <Navbar />
        </b-col>
      </b-row>
      <br>
      <br>
      <b-row>
        <b-col
          cols="10" lg="6" md="6" xl="6"
          offset="1" offset-md="3" offset-lg="3" offset-xl="3"
        >
          <h2>Dotcloud App</h2>

          <p>Mit diesem Programm können PDF-Dateien mit integrierter Braille-Dot-Matrix geprägt werden.</p>

          <p>Unterstützt wird dabei der <i>INDEX Basic-D V5</i> Embosser ab Windows 10.</p>

          <p><a href="/Installer%20dotCloud%20PrinterApp.msi">DotCloud App jetzt herunterladen.</a></p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
    import Navbar from "@/components/Navbar";

    export default {
     name: "DotcloudApp",
     components: {
      Navbar
     },
    }
</script>

<style scoped>

</style>