<template>
  <b-modal id="new-font-modal" hide-footer hide-header @opened="onShown()">
    <p v-if="error.has" class="alert alert-danger">
      {{ error.message }}
    </p>
    <div class="d-block text-left">
      <b-form-group
        id="input-font-name"
        label="Name der Schrift"
        label-for="fontName"
      >
        <b-form-input
          v-model="fontName" name="fontName" value="" class="form-control"
          size="sm"
        />
      </b-form-group>


      <b-form-group
        id="input-base-font-name"
        label="Vorlage"
        label-for="baseFontName"
      >
        <b-form-select
          v-model="baseFontName" name="baseFontName" class="form-control"
          size="sm"
          aria-placeholder="Ausgangsschrift auswählen"
        >
          <option value="" disabled selected>
            Ausgangsschrift auswählen
          </option>
          <option v-for="(item, key) in filterLanguage()" :key="key" :value="key">
            {{ item }}
          </option>
        </b-form-select>
      </b-form-group>
      <b-container>
        <b-row>
          <div class="offset-2 col-sm-4">
            <b-button
              type="submit"
              variant="primary"
              :disabled="!fontName.length"
              @click="onSave"
            >
              OK
            </b-button>
          </div>
          <div class="col-sm-4">
            <b-button
              type="submit"
              variant="secondary"
              @click="useModal('new-font-modal').hide()"
            >
              Schließen
            </b-button>
          </div>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>
<script>

    import {mapActions, mapState} from "vuex";
    import {useModal} from "bootstrap-vue-next";
    import { useToast } from 'vue-toastification'

    const toast = useToast()

    export default {
     name: 'ModalNewFont',
     data() {
      return {
       fontName: '',
       baseFontName: null,
       show: true
      }
     },
     computed: {
      ...mapState({
       fontNames: state => state.fontEditor.fontNames,
       name: state => state.fontEditor.name,
       error: state => state.fontEditor.error
      }),
      name: {
       get () {
        return this.$store.state.fontEditor.name
       },    set (name) {
        this.$store.commit('fontEditor/updateName', name)
       }
      }
     },
     mounted() {
      this.loadFontList();
     },
     methods: {
      useModal,
      filterLanguage() {
       return this.fontNames;
      },
      onSave() {
       this.createFont({
        fontName: this.fontName,
        baseFontName: this.baseFontName
       }).then( function() {
        if (!this.$store.state.fontEditor.error.has) {
         useModal('new-font-modal').hide()
         toast('Schrift ' + this.fontName + ' wurde erfolgreich angelegt.')
         this.name = this.fontName.toUpperCase()
         this.loadFont()
        }
        this.loadLanguages();
       })
      },
      onShown() {
       this.$store.commit('fontEditor/clearError');
       this.fontName = '';
      },
      ...mapActions({
       loadFontList: 'fontEditor/loadFontList',
       createFont: 'fontEditor/createFont',
       updateName: 'fontEditor/updateName',
       clearError: 'fontEditor/clearError',
       loadFont: 'fontEditor/loadFont',
       loadLanguages: 'cuEditor/getReplacements'
      }),
     },
    }

</script>

<style></style>