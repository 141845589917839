<template>
  <div class="imprint-container">
    <b-container fluid>
      <b-row>
        <b-col cols="12" lg="12" md="12">
          <Navbar />
        </b-col>
      </b-row>
      <br>
      <br>
      <b-row>
        <b-col
          cols="10" lg="6" md="6" xl="6"
          offset="1" offset-md="3" offset-lg="3" offset-xl="3"
        >
          <br>
          <br>
          <h2>IMPRESSUM</h2>
          <h4>DotCloud</h4>
          <p>Web: www.dotcloud.de</p>

          <p>
            Inh. Peter Wassermann<br>
            Schulstr. 5<br>
            73650 Winterbach<br>
            Steuer-Nr. 82378/36754
          </p>

          <p>
            Verantwortlich für den Inhalt:<br>
            Peter Wassermann<br>
            E-Mail: pw(at)logosmedien(dot)de
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
    import Navbar from "@/components/Navbar";

    export default {
     name: "Privacy",
     components: {
      Navbar
     },
    }
</script>

<style scoped>

</style>