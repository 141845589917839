<template>
  <b-card-text>
    <BrailleCodingSettingsForm />
  </b-card-text>
</template>

<script>
    import BrailleCodingSettingsForm from "./forms/BrailleCodingSettingsForm";
    export default {
     name: "BrailleCodingSettings",
     components: {BrailleCodingSettingsForm}
    }
</script>

<style scoped>
</style>