<template>
  <b-container class="letters-list ">
    <div v-for="(pageIndex) in normalePages" :key="pageIndex">
      <b-row class="header-row">
        <b-col
          xs="12" sm="12" md="12"
          lg="12"
        >
          <h4>{{ getPageTitle(pageIndex) }}</h4>
        </b-col>
      </b-row>
      <b-row v-for="(letterKey, key) in getPagination(pageIndex)" :key="key" :value="letterKey">
        <b-col
          xs="12" sm="12" md="6"
          lg="3"
        >
          <Letter :show-letter="page.end >= letterKey" :letter-key="letterKey" />
        </b-col>
        <b-col
          xs="12" sm="12" md="6"
          lg="3"
        >
          <Letter :show-letter="page.end >= (letterKey+1)" :letter-key="letterKey + 1" />
        </b-col>
        <b-col
          xs="12" sm="12" md="6"
          lg="3"
        >
          <Letter :show-letter="page.end >= (letterKey +2)" :letter-key="letterKey + 2" />
        </b-col>
        <b-col
          xs="12" sm="12" md="6"
          lg="3"
        >
          <Letter :show-letter="page.end >= (letterKey +3)" :letter-key="letterKey + 3" />
        </b-col>
      </b-row>
    </div>
    <div>
      <b-row class="header-row">
        <b-col
          xs="12" sm="12" md="12"
          lg="12"
        >
          <h4>{{ getPageTitle(3) }}</h4>
        </b-col>
      </b-row>
      <b-row v-for="(letterKey, key) in getPagination(3)" :key="key" :value="letterKey">
        <b-col
          xs="12" sm="12" md="6"
          lg="3"
        >
          <Letter :show-letter="page.end >= letterKey" :letter-key="letterKey" />
        </b-col>
        <b-col
          xs="12" sm="12" md="6"
          lg="3"
        >
          <Letter :show-letter="page.end >= (letterKey +1)" :letter-key="letterKey + 1" />
        </b-col>
        <b-col
          xs="12" sm="12" md="6"
          lg="3"
        >
          <Letter :show-letter="page.end >= (letterKey +2)" :letter-key="letterKey + 2" />
        </b-col>
        <b-col
          xs="12" sm="12" md="6"
          lg="3"
        >
          <Letter :show-letter="page.end >= (letterKey +3)" :letter-key="letterKey + 3" />
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
    import {mapState} from "vuex";
    import Letter from "@/pages/bfteditor/components/Letter";
    import {BFT_NO_INDICES, BFT_PAGE_ASCII, BFT_PAGE_ARAB_NUMBERS, BFT_PAGE_ROM_NUMBERS} from "../../../store/store";

    export default {
     name: "Letters",
     components: {
      Letter
     },
     data() {
      return {
       cols: 4,
       no_indices: BFT_NO_INDICES,
       normalePages: [BFT_PAGE_ASCII, BFT_PAGE_ARAB_NUMBERS, BFT_PAGE_ROM_NUMBERS]
      }
     },
     computed: {
      ...mapState(['fontEditor/letters']),
     },
     
     methods: {
      getPage: function(pageNumber) {
       this.page = this.$store.getters["fontEditor/getPage"](pageNumber);
       return this.page;
      },
      getPageTitle: function(pageNumber) {
       var page = this.getPage(pageNumber);
       return page.title;
      },
      getPagination: function(pageNumber) {
       var page = this.getPage(pageNumber);
       let keys = []
       for (let row = page.start; row < page.end  + 1; row += this.cols) {
        keys.push(row);
       }
       return keys;
      }
     }
    }
</script>

<style scoped>
  .row {
    margin-right: 0px;
    margin-left: 0px;
  }
  .letters-list {
    margin-left: 0px;
    border-left: #0087b8 1px solid;
  }

  .letters-list:first-child {
    border-top: #0087b8 1px solid;
  }

  .header-row {
    border-right: #0087b8 1px solid;
    border-bottom: #0087b8 1px solid;
    background-color: rgba(0, 135, 184, 0.16);
  }
  .header-row h4 {
    margin-bottom: 10px;
    margin-top: 12px;
    padding-left: 12px;
  }
</style>