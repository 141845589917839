<template>
  <div class="login-container">
    <b-container fluid>
      <b-row>
        <b-col cols="12" lg="12" md="12">
          <Navbar />
        </b-col>
      </b-row>
      <b-row class="login-row col-md-6 offset-md-3 col-lg-4 offset-lg-4 col-xl-3 offset-xl-4 text-center">
        <img alt="dotCloud Logo" class="dotcloud-logo text-center" src="../../assets/logo.png">
        <h2>Willkommen</h2>
        <b-form ref="form" @submit.prevent="submit()">
          <b-form-input
            v-model="form.username"
            name="username"
            label="Benutzername"
            type="text"
            placeholder="Benutzername"
            size="sm"
            required
          />

          <b-form-input
            v-model="form.password"
            name="password"
            label="Passwort"
            type="password"
            placeholder="Passwort"
            size="sm"
            required
          />
          <p v-if="showError" id="error">
            Benutzername oder Passwort ist nicht korrekt.
          </p>
          <b-button type="submit" class="mt-4 btn-primary" color="primary" value="log in">
            Login
          </b-button>
        </b-form>
      </b-row>
    </b-container>
  </div>
</template>

<script>
    import Navbar from "../Navbar";
    import { mapActions } from "vuex";

    export default {
     name: "Login",
     components: {
      Navbar
     },
     data() {
      return {
       form: {
        username: "",
        password: "",
       },
       showError: false
      };
     },
     methods: {
      ...mapActions(["LogIn"]),
      async submit() {
       const User = new FormData();
       User.append("username", this.form.username);
       User.append("password", this.form.password);
       try {
        await this.LogIn(User);
        await this.$router.push("/");
        this.showError = false
       } catch (error) {
        this.showError = true
       }
      },
     },
    }
</script>

<style scoped>
body,
html
{
 min-height: 100% !important;
 height: 100% !important;
}

.dotcloud-logo {
 margin: 15px auto 45px auto;
 width: 75%;
}

.login-container {
 height: 100% !important;
 border: 1px solid rgba(0,0,0,.125);
 text-align: center;
 background-image: linear-gradient(to bottom, #ccf9fc, #4fc0e2);
 color: #ffffff;
}

.vertical-center .form-control:focus {
 border-color: #2554FF;
 box-shadow: none;
}

.vertical-center h3 {
 text-align: center;
 margin: 0;
 line-height: 1;
 padding-bottom: 20px;
}

label {
 font-weight: 500;
}

.form-control {
 margin: 5px 0px;
}

#error {
 margin-bottom: 0;
 line-height: normal;
 font-size: 14px;
}

.login-row {
 background-color: #0087b870;
 border-radius: .25em;
 margin-top: 15px;
 padding: 5px;
 padding-bottom: 20px;
}

</style>