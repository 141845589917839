<template>
  <div class="praegen-container">
    <b-container fluid>
      <b-row>
        <b-col cols="12" lg="12" md="12">
          <Navbar />
        </b-col>
      </b-row>
      <br>
      <br>
      <b-row>
        <b-col
          cols="10" lg="6" md="6" xl="6"
          offset="1" offset-md="3" offset-lg="3" offset-xl="3"
        >
          <b-button variant="primary" @click="praegenWithAxios()">
            Dokument prägen
          </b-button>
          <br><br>
          <div class="h1 col-lg-1 float-left">
            <b-icon-exclamation-triangle-fill variant="info" />
          </div>
          <p>
            Wenn die dotCloud App mit dem aktuellen Dokument nicht öffnet, dann muss die App noch installiert werden.<br>
            <router-link :to="{name: 'dotcloud-app'}">
              Hier geht es zum Download der Installationsdatei.
            </router-link>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
    import Navbar from "@/components/Navbar";
    import {config} from "@/config";

    export default {
     name: "Praegen",
     components: {
      Navbar
     },
     methods: {
      praegenWithAxios(){
       const uri = this.getDocumentUrl().replace('http://', 'pdfbdm://').replace('https://', 'pdfbdm://');
       window.location.href = uri;
      },
      getDocumentUrl() {
       return config.api + config.documentPath + '/' + this.$store.state.documentId
      },
     }
    }
</script>

<style scoped>

</style>