<template>
  <router-view />
</template>

<script>
    export default {
     name: 'App',
    }
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /*margin-top: 60px;*/
}

.card-header ul {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
</style>
