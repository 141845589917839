<template>
  <div id="app">
    <b-container fluid class="h-100">
      <b-row>
        <b-col cols="12" lg="12" md="12">
          <Navbar />
        </b-col>
      </b-row>
      <b-row class="h-100">
        <b-col cols="3" lg="3" md="3" xs="12">
          <SettingsColumnBFTEditor />
        </b-col>
        <b-col xs="12">
          <Letters />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
    import Navbar from "@/components/Navbar";
    import SettingsColumnBFTEditor from "@/pages/bfteditor/components/SettingsColumn";
    import Letters from "@/pages/bfteditor/components/Letters";

    export default {
     name: 'BFTEditor',
     components: {
      Letters,
      SettingsColumnBFTEditor,
      Navbar
     },
     computed: {
     },
     methods: {
     },
    }
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  /*margin-top: 60px;*/
}

.card-header ul {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
</style>
