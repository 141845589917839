<template>
  <div class="preview-container">
    <div>
      <b-alert :show="alertState" variant="danger" :max="10" dismissible>
        {{ alertMessage }}
      </b-alert>
    </div>
    <br>
    <img alt="dotCloud Logo" src="./../assets/logo.png" width="350">
    <h2>Willkommen</h2>
    <Upload />
  </div>
</template>

<script>
    import Upload from './Upload.vue'
    import {mapState} from 'vuex'

    export default {
     name: "Preview",
     components: {
      Upload
     },
     data() {
      return {
      }
     },
     computed: {
      ...mapState(['alertState', 'alertMessage'])
     },
    }
</script>

<style scoped>
    .preview-container {
        border: 1px solid rgba(0,0,0,.125);
        border-top: 0px;
        text-align: center;
        height: 100%;
        background-image: linear-gradient(to bottom, #ccf9fc, #4fc0e2);
    }

  h2 {
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .alert {
     border-radius: unset;
     border: none;
  }
  .alert-danger {
     color: #594244;
     background-color: #f8d7dad4;
  }
</style>