//import {createApp} from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import store from '../store/store'

import Main from '../Main.vue'
import Login from '../components/login/Login'
import Privacy from '../components/Privacy'
import Imprint from '../components/Imprint'
import BFTEditor from '../pages/bfteditor/BFTEditor'
import DotcloudApp from '../components/DotcloudApp'
import Praegen from '../components/Praegen'
//import App from "@/App.vue";

//Vue.use(VueRouter)
//const app = createApp(App);
//app.use(VueRouter)


const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: { guest: true },
    },
    {
        path: '/',
        name: 'main',
        component: Main,
        meta: {requiresAuth: true},
    },
    {
        path: '/datenschutz',
        name: 'privacy',
        component: Privacy
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: Imprint
    },
    {
        path: '/bft-editor',
        name: 'bft-editor',
        component: BFTEditor,
        meta: {requiresAuth: true},
    },
    {
        path: '/dotcloud-app',
        name: 'dotcloud-app',
        component: DotcloudApp,
        meta: {requiresAuth: true},
    },
    {
        path: '/praegen',
        name: 'praegen',
        component: Praegen,
        meta: {requiresAuth: true},
    },
]

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
            next("/");
            return;
        }
        next();
    } else {
        next();
    }
});

export default router