<template>
  <div class="braille">
    <div class="dot br-1" :class="{active: $store.getters['fontEditor/getDot'](letterKey, 1)}" @click="toggleDot(1)" />
    <div class="dot br-2" :class="{active: $store.getters['fontEditor/getDot'](letterKey, 2)}" @click="toggleDot(2)" />
    <div class="dot br-3" :class="{active: $store.getters['fontEditor/getDot'](letterKey, 3)}" @click="toggleDot(3)" />
    <div class="dot br-4" :class="{active: $store.getters['fontEditor/getDot'](letterKey, 7)}" @click="toggleDot(7)" />
    <div class="dot br-5" :class="{active: $store.getters['fontEditor/getDot'](letterKey, 4)}" @click="toggleDot(4)" />
    <div class="dot br-6" :class="{active: $store.getters['fontEditor/getDot'](letterKey, 5)}" @click="toggleDot(5)" />
    <div class="dot br-7" :class="{active: $store.getters['fontEditor/getDot'](letterKey, 6)}" @click="toggleDot(6)" />
    <div class="dot br-8" :class="{active: $store.getters['fontEditor/getDot'](letterKey, 8)}" @click="toggleDot(8)" />
  </div>
</template>

<script>
    import {mapState, mapGetters} from "vuex";
    import {BFT_ATTR_PUNCTATION, BFT_ATTR_NUMDIGIT, BFT_ATTR_MATHSIGN, BFT_ATTR_CAPITAL, BFT_ATTR_SMALLETTER} from "../../../store/store";

    export default {
     name: "Braille",
     components: {
     },
     props: {
      letterKey: {
       type: Number,
       default: 0
      }
     },
     data() {
      return {
       cols: 3,
      }
     },
     computed: {
      ...mapState(['letters']),
      ...mapGetters(['getDot'])
     },
     methods: {
      toggleDot(dot) {
       this.$store.commit({
        type: 'fontEditor/toggleDot',
        key: this.letterKey,
        dot: dot
       });
       this.$forceUpdate()
      },
      getDisplayValue() {
       if (this.attribute == BFT_ATTR_CAPITAL)
        return 'ABC';
       if (this.attribute == BFT_ATTR_SMALLETTER)
        return 'abc';
       if (this.attribute == BFT_ATTR_NUMDIGIT)
        return '123';
       if (this.attribute == BFT_ATTR_MATHSIGN)
        return '+-*';
       if (this.attribute == BFT_ATTR_PUNCTATION)
        return ',.;';
      },
     }
    }
</script>

<style scoped>
  .braille {
    height: 40px;
    width: 20px;
    position: relative;
  }
  .dot {
    width: 8px;
    height: 8px;
    position: absolute;
    border: 1px solid lightgrey;
    border-radius: 5px;
    margin: 1px;
    cursor: pointer;
  }
  .dot.active {
    border: 1px solid black;
    background-color: black;
  }
  .dot.br-1 {
    top: 0px;
    left: 0px
  }
  .dot.br-2 {
    top: 10px;
    left: 0px
  }
  .dot.br-3 {
    top: 20px;
    left: 0px
  }
  .dot.br-4 {
    bottom: 0px;
    left: 0px
  }

  .dot.br-5 {
    top: 0px;
    right: 0px
  }
  .dot.br-6 {
    top: 10px;
    right: 0px
  }
  .dot.br-7 {
    top: 20px;
    right: 0px
  }
  .dot.br-8 {
    bottom: 0px;
    right: 0px
  }
  .braille .dot-active {

  }
</style>